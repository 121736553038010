<template>
  <div class="rounded-lg shadow-none">
    <div class="d-flex align-items-end justify-content-start" style="gap: 10px"
      v-if="isSelectAll || selectedItem.length">
      <b-button v-if="roleID !== 5" variant="outline-danger" @click="onSubmitUnPublishSelected"
        :disabled="isLoadingUnPublish">
        <b-spinner small v-if="isLoadingUnPublish"></b-spinner>
        <em class="fa fa-eye-slash" v-else></em>
        UnPublished
      </b-button>
      <label for="" v-if="roleID !== 5">{{ selectedItem.length }} selected item</label>

    </div>
    <t-table :headers="fields" :data="books" @row-selected="onRowSelected" ref="tableList" class="shadow-none" >
      <template v-if="!books.length" v-slot:tbody="props">
            <tbody :class="props.tbodyClass">
              <tr :class="[props.trClass, 'text-center']">
                <td :class="props.tdClass" colspan="15">
                  There are no records to show
                </td>
              </tr>
            </tbody>
          </template>
          <template v-if="isLoading" v-slot:tbody="props">
            <tbody :class="props.tbodyClass">
              <tr :class="[props.trClass, 'text-center']">
                <td :class="props.tdClass" colspan="15">
                  <div role="status" class="flex justify-center items-center h-72">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 text-center animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only text-center items-center">Loading...</span>
</div>
                </td>
              </tr>
            </tbody>
          </template>
          
      <template v-slot:thead="props">
        <thead>
          <tr>
            <th>
              <t-checkbox v-if="roleID !== 5" class="ml-4" v-model="isSelectAll" @input="onSelectAllRows">
              </t-checkbox>
            </th>
            <th :class="props.thClass" class="text-center">
              No
            </th> 
  
            <th :class="props.thClass">
              Name
            </th>
            <th :class="props.thClass">
              Content Type
            </th>
            <th :class="props.thClass">
              Is Active
            </th>
            <th :class="props.thClass">
              Item Status
            </th>
            <th :class="props.thClass">
              Printed Price
            </th>
            <th :class="props.thClass">
              Page Count
            </th>
            <th :class="props.thClass">
              Size
            </th>
            <th :class="props.thClass">
                  <div class="flex items-center"  @click="sortBy('release_date')">
                    RELEASE DATE
                    <span href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg>
                  </span>
                </div>
            </th>
            <th :class="props.thClass">
              <div class="flex items-center"  @click="sortBy('release_schedule')">
                RELEASE SCHEDULE
                    <span href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg>
                  </span>
                </div>
            </th>
            <th :class="props.thClass">
              VENDOR
            </th>
            <th :class="props.thClass">
              Action
            </th>
          </tr>
        </thead>
      </template>

      <template slot="row" slot-scope="props">
        <tr @click="onRowSelected(props.row.id)" :class="isRowSelected(props.row.id) ? 'bg-gray-400' : 'bg-white'"
          class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3">
          <td :class="props.tdClass">
            <i :class="isRowSelected(props.row.id) ? 'fa-solid fa-check text-left ml-3' : ''"></i>
          </td>
          <td :class="props.tdClass">
            {{ props.rowIndex + 1 }}
          </td>
          <td :class="props.tdClass" class="max-w-xs">
            <router-link :to="{ name: DetailItems.name, params: { id: props.row.id } }"
              class="text-center text-blue-700 ">
              {{ props.row.name }}
            </router-link>

            <button size="sm" variant="outline" @click.stop="copyTextToClipboard(props.row.id)" class="relative">
              <span class="flex items-center relative top-1 text-sm pr-5">
                <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M7 9v6a4 4 0 0 0 4 4h4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1v2Z" clip-rule="evenodd" />
                  <path fill-rule="evenodd"
                    d="M13 3.054V7H9.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 13 3.054ZM15 3v4a2 2 0 0 1-2 2H9v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
                    clip-rule="evenodd" />
                </svg>
                {{ props.row.id }}
              </span>


            </button>
          </td>
          <td :class="props.tdClass" class="uppercase w-10">
            {{ props.row.content_type }}
          </td>
          <td :class="props.tdClass">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_active, 'bg-gray-200 text-gray-800': !props.row.is_active }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_active) }}

            </span>
          </td>
          <td :class="props.tdClass" class="w-36">
            <span v-if="props.row.item_status == itemStatus.Unpublished"
              class="bg-red-100 rounded-lg text-xs text-red-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.Published"
              class="bg-blue-100 p-1 rounded-lg text-xs text-blue-800">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.Uploaded"
              class="bg-yellow-100 rounded-lg text-xs text-yellow-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.UnpublishedMcgrawhill"
              class="bg-gray-100 rounded-lg text-xs text-gray-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else class="bg-green-100 p-1 rounded-lg text-xs text-green-800">
              {{ props.row.item_status }}
            </span>
          </td>
          <td :class="props.tdClass" class="uppercase text-left">
            {{
        formatPrice(props.row.printed_price, props.row.printed_currency_code)
      }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.page_count }}
          </td>
          <td :class="props.tdClass">
            {{ convertMegaBite(props.row.size) }}
          </td>
          <td :class="props.tdClass">
            {{ formatterDate(props.row.release_date) }}
          </td>
          <td :class="props.tdClass">
            {{ formatterDate(props.row.release_schedule) }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.vendor }}
          </td>

          <td :class="props.tdClass" class="text-right pr-3">
            <t-dropdown>
              <div slot="trigger" slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }">
                <button id="user-menu" :class="{ ' text-gray-500 ': isShown }" aria-label="User menu"
                  aria-haspopup="true" @focus.stop="focusHandler" @blur.stop="blurHandler"
                  @keydown.stop="keydownHandler" @click.stop="mousedownHandler" class="cursor-pointer">
                  <i class="fa-solid fa-ellipsis"></i>
                </button>
              </div>

              <div  slot-scope="{ hide, blurHandler }">
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">

                  <div v-if="roleID !== 5" @click="actionUpdateStatus(props.row, 'publish', status.Published)"
                    class="flex items-center">
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-width="2"
                        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                      <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    <span class="flex items-center ml-2">
                      Published
                    </span>
                  </div>

                </button>
                <button
                  class="block text-left  w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="roleID !== 5" class="flex items-center" variant="danger"
                    @click="actionUpdateStatus(props.row, 'unpublish', status.Unpublished)">
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    <span class="flex items-center ml-2">
                      UnPublished
                    </span>

                  </div>
                </button>
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div variant="default" @click="actionEdit(props.row)" class="flex items-center">
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
                    </svg>
                    <span class="flex items-center ml-2">
                      Edit Items
                    </span>
                  </div>
                </button>
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="props.row.item_type != item_types_audio">
                    <div
                      variant="black"
                      @click="
                        actionUpdateStatus(
                          props.row,
                          'mcgrawhill content',
                          status.UnpublishedMcgrawhill,
                        )
                      "
                      v-if="permBtnMcGraw()"
                    >
                      mcgrawhill content
                          </div>
                  </div>
                
                </button>
             
                <button v-if="props.row.item_type == 'book'"
                  class="block text-left  w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="!ownVendor.id" class="flex justify-self-start">
                    <div>
                    <i class="fa-solid fa-paper-plane"></i> 
                    </div>
                  <div class="ml-3" @click="actionSendAx(props.row)">
                      Register to AX
                  </div>
                 
                    </div>
                </button>
                <button  v-if="props.row.item_type == 'book'"
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  
                  <div v-if="!ownVendor.id" class="flex justify-start">
                    <div>
                    <i class="fa-solid fa-paper-plane"></i>
                  </div>
                   <div class="ml-3" variant="default"
                  @click="actionSendAxPremium(props.row)">
                  Register to AX (PREMIUM)
                </div>
                
                    </div>
                </button>
            
              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>
    </t-table>

    <t-modal ref="confirmationModal">
      <div class="text-sm flex-col text-center">
        <label class="text-center font-semibold">Are You Sure?</label>
        <p class="text-left text-gray-500">This action is quite dangerous, do you still want to continue?</p>
      </div>
      <div class="flex justify-center">
            <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" @click="cancel">Cancel</button>
            <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm" @click="sendRequestByStatus">Yes</button>
      </div>
    </t-modal>

    <t-modal ref="modalUpdateStatus">
      <div class="text-sm flex-col">
      <label class="text-left font-semibold"> Are you sure want to {{this.titleModalUpdateStatus}} ?</label>
      </div>
      <div>
        <p class="text-left text-gray-500">{{this.statusModalUpdate}} {{nameModalUpdateStatus}}</p>
      </div>
      <div class="flex justify-center">
            <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" @click="cancel">Cancel</button>
            <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm" @click="confirmUpdateStatus">Yes</button>
      </div>
    </t-modal>
  </div>
</template>

<script>
import API from '../../plugins/http';
import moment from 'moment';
import constant from '../../store/constant';
import {
  BookEditPath,
  DetailItems,
  MagazineEditPath,
  NewspaperEditPath,
  AudioEditPath,
  AudioEditChapterPath,
} from '../../router/content';
import { mapActions, mapGetters, mapState } from 'vuex';
import { EventBus } from '../../../src/event-bus';
const { ROLES } = constant;
const McGrawHill = [1001]; // McGraw-Hill Education

export default {
  name: 'Books',
  props: {
    books: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    reloadPage: {
      type: Function,
      required: false,
      default() { },
    },
  },
  data() {
    return {
      showModal: false,
      roleID: JSON.parse(localStorage.getItem("session_auth")).role_id,
      Form: {
        id: null,
        id_ax: null,
      },
      confirmed: false,
      statusModalUpdate: '',
      nameModalUpdateStatus: '',
      titleModalUpdateStatus:'',
      dataItemModalUpateStatus:'',
      item_types_audio: 'audio book',
      fields: [
        {
          key: 'no',
          label: 'No',
          tdClass: 'text-uppercase',
        },
        {
          key: 'title',
          label: 'Name',
          tdClass: 'text-uppercase',

        },
        {
          key: 'content_type',
          tdClass: 'text-uppercase',
        },
        {
          key: 'is_active',
          label: 'Is Active',
          formatter: (value) => {
            return value ? 'Yes' : 'No';
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'item_status',
          label: 'Item Status',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
          tdStyle: { width: '250px' },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'printed_price',
          label: 'Printed Price',
          thClass: 'text-right',
          tdStyle: { width: '300px' },
        },
        {
          key: 'page_count',
          label: 'Page Count',
        },
        {
          key: 'size',
          label: 'Size',
          thClass: 'text-right',
        },
        {
          key: 'release_date',
          label: 'Release Date',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        {
          key: 'release_schedule',
          label: 'Release schedule',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        { key: 'vendor_name', label: 'Vendor' },
        { key: 'status', label: 'Action' },
      ],
      DetailItems,
      status: {
        Published: constant.ITEM_STATUS.Published,
        Unpublished: constant.ITEM_STATUS.Unpublished,
        UnpublishedMcgrawhill: constant.ITEM_STATUS.UnpublishedMcgrawhill,
      },
      itemStatus: constant.ITEM_STATUS,
      isSelectAll: false,
      isLoadingUnPublish: false,
      selectedItem: [],
    };
  },
  watch: {
    books() {
      this.isSelectAll = false;
    },
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapState({
      ownVendor: (state) => state.vendors.item,
    }),
  },
  created() {
    this.fetchOwnVendors();
  },
  methods: {
    ...mapActions('vendors', ['fetchOwnVendors']),
    ...mapActions('items', ['bulkUnPublish']),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }
        return formatter.format(value);
      }
      return '0';
    },
    convertMegaBite(size) {
      if (size) {
        return (size / 1000 ** 2).toFixed(1) + ' MB';
      }
      return '0';
    },
    actionSend() {
      let payload = {
        id_ax: this.Form.id_ax,
        id: this.Form.id,
      };
      API.post('ofs-catalog', payload)
        .then((response) => {
          console.log('ini res', response);
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: 'Success send ofs',
            timer: 3000,
            text: this.message,
          });
        })
        .catch((error) => {
          console.log('ini error', error);
          this.$swal({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Failed send ofs',
            timer: 3000,
            text: this.message,
          });
        });
    },
    actionSendOfs(item) {
      this.showModal = true;
      this.Form.id = item.id;
    },
    mapUrl(item) {
      const itemType = constant.ITEM_TYPES;
      if (item.item_type == itemType.BOOK.toLowerCase()) {
        return BookEditPath.name;
      }
      if (item.item_type == itemType.MAGAZINE.toLowerCase()) {
        return MagazineEditPath.name;
      }
      if (item.item_type == itemType.NEWSPAPER.toLowerCase()) {
        return NewspaperEditPath.name;
      }
      if (item.item_type == itemType.AUDIOBOOK.toLowerCase()) {
        return AudioEditPath.name;
      }
      if (item.item_type == 'Chapter') {
        return AudioEditChapterPath.name;
      }
    },
    actionEdit(item) {
      this.$router.push({
        name: this.mapUrl(item),
        params: {
          id: item.id,
        },
      });
    },
    actionSendAx(item) {
      let payload = {
        id: item.id,
      };
      API.post('register-ax', payload)
        .then(() => {
          EventBus.$emit('show-toast', {
              message: 'Success send ax',
              type: 'success',
            });
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit('show-toast', {
              message: 'Failed send ax',
              type: 'error',
            });
        });
    },

      actionSendAxPremium(item) {
        let payload = {
          id: item.id,
        };
        console.log('pay',payload)
        API.post('register-ax/premium', payload)
        .then(() => {
          EventBus.$emit('show-toast', {
              message: 'Success send ax premium item.',
              type: 'success',
            });
        })
        .catch((err) => {
          if (err.response.status == 404) {
            EventBus.$emit('show-toast', {
              message: 'Book not Found',
              type: 'error',
            });
          } else {
            EventBus.$emit('show-toast', {
              message: err.response.data.message,
              type: 'error',
            });
          }
        });
    },
    async sendRequestByStatus({ item_id, status, item_type, title }) {
      return API.put(`items/status/${item_id}`, { status, item_type })
        .then((response) => {
          EventBus.$emit('show-toast', {
              message: `Success ${title}`,
              type: 'success',
            });
            setTimeout(()=>{
              this.reloadPage();
              this.$refs.modalUpdateStatus.hide();
            },3000)  
          return response;
        })
        .catch((error) => {
          EventBus.$emit('show-toast', {
              message: `Failed unpublish`,
              type: 'error',
            });
          return error;
        });
    },
    actionUpdateStatus(item, title, status) {
      this.dataItemModalUpateStatus = item
      this.$refs.modalUpdateStatus.show();
      this.titleModalUpdateStatus= title;
      this.statusModalUpdate = status;
      this.nameModalUpdateStatus = item.name
    },
    async confirmUpdateStatus() {
          await this.sendRequestByStatus({
            item_id: this.dataItemModalUpateStatus.id,
            status:  this.statusModalUpdate,
            item_type: this.dataItemModalUpateStatus.item_type,
            title:this.titleModalUpdateStatus,
          })
    },
    permBtnMcGraw() {
      switch (this.auth?.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          if (McGrawHill.includes(this.ownVendor?.id)) return true;
          return false;
      }
    },
    findIndexRowMcGrawHill(items = []) {
      const statusMcGrawhill = this.status.UnpublishedMcgrawhill;
      return items
        .map((item, idx) => {
          if (item.item_status == statusMcGrawhill) {
            return idx;
          }
        })
        .filter((val) => ![undefined, null].includes(val));
    },
    unselectRowByIndex(indexItem = []) {
      indexItem.forEach((index) => {
        this.$refs.tableList.unselectRow(index);
      });
    },
  
    onSelectAllRows() {
      if (!this.books) return; // Ensure this.books is defined

      if (this.isSelectAll) {
        this.selectedItem = this.books.map(book => book.id);
      } else {
        this.selectedItem = [];
      }
    },


    isRowSelected(rowId) {
      return this.selectedItem && this.selectedItem.includes(rowId);
    },
    formatBoolean(value) {
      return value ? 'Yes' : 'No';
    },
    formatterDate: (value) => {
      return moment(value).format('DD MMM YYYY');
    },
    onRowSelected(rowId) {
      const index = this.selectedItem.indexOf(rowId);
      if (index > -1) {
        this.selectedItem && this.selectedItem.includes(rowId);
        this.selectedItem.splice(index, 1);
      } else {
        this.selectedItem.push(rowId);
      }
    },

    // async confirmBulkUnPublish() {
    //     this.$refs.confirmationModal.show();
    //     this.confirm()
    //     this.cancel()
    // },

    confirm() {
      this.confirmed = true
      const items_id = this.selectedItem;
      this.isLoadingUnPublish = true;
      this.bulkUnPublish({
        items_id,
      })
        .then((response) => {
          this.$refs.confirmationModal.hide();
          if (response.status == 201) {
            EventBus.$emit('show-toast', {
              message: 'Success Unpublished',
              type: 'success',
            });
          } else {
            EventBus.$emit('show-toast', {
              message: 'Success Unpublished',
              type: 'error',
            });
          }
        })
        .catch(() => {
          const message =
            'Opps .. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.isLoadingUnPublish = false;
          this.reloadPage();
        });

    },

    cancel() {
      this.$refs.confirmationModal.hide();
      this.$refs.modalUpdateStatus.hide();
    },
    sortBy(criteria) {
        this.$emit('sort-by', criteria);
      },
    async onSubmitUnPublishSelected() {
      this.$refs.confirmationModal.show();
    },
  },
};
</script>
